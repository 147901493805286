

export default {
    components: {

    },
    data() {
        return {
            // elmentList: [
            //     {
            //         pcElementId:326,
            //         phoneElementId: 338,
            //         descList: [
            //             {
            //                 descName: 'Sevnce Robotics Employment Service',
            //                 sort: 1
            //             }, {
            //                 descName: "Replacing manual work with robot, early use through convenient 'rental'",
            //                 sort: 2
            //             }, {
            //                 descName: 'low daily payment, start  immediately',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort: 1
            //     },
            //     {
            //         pcElementId:327,
            //         phoneElementId: 339,
            //         descList: [
            //             {
            //                 descName: 'Daily payment as low as 1000 CNY',
            //                 sort: 1
            //             }, {
            //                 descName: 'Algorithm maintenance one-stop full coverage',
            //                 sort: 2
            //             },
            //             {
            //                 descName: 'Flexible convert to purchase at your choice',
            //                 sort: 3
            //             }, {
            //                 descName: 'Enjoy a new experience of digital intelligence',
            //                 sort: 4
            //             }, {
            //                 descName: "Employment services help easily achieve 'robot replaces manual work'",
            //                 sort: 4
            //             }],
            //         type: 'image',
            //         sort: 2
            //     },
            //     {
            //         pcElementId:328,
            //         phoneElementId: 340,
            //         descList: [
            //             {
            //                 descName: 'Working Conditions',
            //                 sort: 1
            //             },
            //             {
            //                 descName: 'Manual Inspection',
            //                 sort: 2
            //             }, {
            //                 descName: 'Affected by weather factors, adverse weather conditions can easily lead to phenomena such as passive work and lack of manpower, resulting in a large amount of waste of manpower and resources, and even causing safety accidents',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort: 3
            //     },
            //     {
            //         pcElementId:329,
            //         phoneElementId: 341,
            //         descList: [
            //             {
            //                 descName: 'Robot Inspection',
            //                 sort: 1
            //             }, {
            //                 descName: 'Not affected by weather, can achieve 24-hour all-weather inspection, and can work normally in hot or cold environments',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 4
            //     },
            //     {
            //         pcElementId:330,
            //         phoneElementId: 342,
            //         descList: [
            //             {
            //                 descName: 'Inspection Risk',
            //                 sort: 1
            //             },
            //             {
            //                 descName: 'Manual Inspection',
            //                 sort: 2
            //             }, {
            //                 descName: 'High risk, accidents will directly endanger personal safety',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort: 5
            //     },
            //     {
            //         pcElementId:331,
            //         phoneElementId:343,
            //         descList: [
            //             {
            //                 descName: 'Robot Inspection',
            //                 sort: 1
            //             }, {
            //                 descName: 'Low risk, replace personnel inspection, can realize remote operation, to ensure personal safety',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort:6
            //     },
            //     {
            //         pcElementId:332,
            //         phoneElementId: 344,
            //         descList: [
            //             {
            //                 descName: 'Inspection Efficiency',
            //                 sort: 1
            //             },
            //             {
            //                 descName: 'Manual Inspection',
            //                 sort: 2
            //             }, {
            //                 descName: 'The task is heavy, the efficiency is low, the accuracy is affected by experience, environment and other factors. The scope of manual inspection is limited, and the missed detection rate is high',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort:7
            //     },
            //     {
            //         pcElementId:333,
            //         phoneElementId: 345,
            //         descList: [
            //             {
            //                 descName: 'Robot Inspection',
            //                 sort: 1
            //             }, {
            //                 descName: 'Digital operation and maintenance, efficient and convenient, equipped with a leading algorithm, the inspection accuracy is nearly 100%',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort:8
            //     },
            //     {
            //         pcElementId:334,
            //         phoneElementId: 346,
            //         descList: [   {
            //             descName: 'Inspection Data',
            //             sort: 1
            //         }, 
            //             {
            //                 descName: 'Manual Inspection',
            //                 sort: 1
            //             }, {
            //                 descName: 'Unable to conduct real-time inspections and collect data, resulting in data loss and inability to provide comparative data analysis, which can delay the optimal time to eliminate hidden dangers',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort:9
            //     },
            //     {
            //         pcElementId:335,
            //         phoneElementId: 347,
            //         descList: [
            //             {
            //                 descName: 'Robot Inspection',
            //                 sort: 1
            //             }, {
            //                 descName: 'Provide massive data collection and analysis functions, timely upload equipment status inspection data to the management center, facilitating administrators to control the system equipment situation',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort:10
            //     },
            //     {
            //         pcElementId:336,
            //         phoneElementId: 348,
            //         descList: [
            //             {
            //                 descName: 'Cost Expenditure',
            //                 sort: 1
            //             },
            //             {
            //                 descName: 'Manual Inspection',
            //                 sort:2
            //             }, {
            //                 descName: 'The daily inspection cost is over 1,600 CNY, excluding insurance and other expenses (data from a sample survey of 102 large and medium-sized petrochemical enterprises in 2023)',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort:11
            //     },
            //     {
            //         pcElementId:337,
            //         phoneElementId: 349,
            //         descList: [
            //             {
            //                 descName: 'Robot Inspection',
            //                 sort: 1
            //             }, {
            //                 descName: 'The daily employment cost can be as low as 1,000 CNY, including maintenance and algorithm upgrades (specific costs may vary depending on different product and service choices)',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort:12
            //     },
            // ],
            elmentList: [],
        }
    },
    created() {
        // this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: this.$route.query.pageId,//页面id
        //         pageName: this.$route.path,
        //         path: this.$route.path,
        //         remark: '',
        //     }
        // );
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
            setTimeout(() => {
            }, 100);
        });
    },
    methods: {
        retention() {
            this.$router.push('/en/reservation');
        },
        servicePDF() {
            window.open(this.elmentList[16].pcElementId);
        }
    }
}